:root {
    --black: #000000;
}

body {
    background-color: #181b24 !important;
    margin: 0;
    min-height: 100vh !important;
    font-family: 'Helvetica Neue', -apple-system, 'Open Sans', sans-serif;
    @media only screen and (max-width: 767px) {
       
     }
}

.bg-lighter {
    background-color: #DEDEDE;
}

.text-lighter {
    color: #777777;
}

.navbar {
    font-size: 0.9rem;
    font-weight: 600;
    .nav-link {
        color: #EFEFEF !important;
    }
    .dropdown-item {
        font-size: 0.9rem;
        font-weight: 600;
    }
}

.custom-dropdown {
    display: inline;
    .nav-link {
        display: inline;
        padding-left: 0;
    }
    .dropdown-menu {
        top:10px !important;
        min-width: 250px;
    }
    .dropdown-toggle::after {
        display: none;
    }
    .form-check {
        margin-bottom: 0.8rem;
        .form-check-label {
            color: #484848;
            font-weight: 600;
            line-height: 1.2;
        }
        .form-check-input {
            border: #484848;
        }
        
        &:first-child {
            margin-top: 0.8rem;
        }
    }
    .fixed-part {
        overflow: scroll;
        overflow: -moz-scrollbars-vertical; 
        max-height: 200px;
        box-shadow: inset 0px -10px 10px -4px rgba(0,0,0,0.1);
    }
}

.bg-black {
    //background: var(--black);
    @media only screen and (min-width: 767px) {
      //  background: slategray;
      }
}

.link {
    &:hover {
        text-decoration: none;
    }
}

.program-header {
    .logo {
        max-width: 200px;
    }
    .certification-text {
        font-size: 14px;
    }
}

.program-content-web {
    p {
        margin: 0px !important;
        word-wrap: break-word !important;
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 1.6666666666666667em !important;
        color: #484848;
        margin-bottom: 2.5rem !important;
    }
    ol {
        list-style: none;
        counter-reset: my-awesome-counter;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        li {
            counter-increment: my-awesome-counter;
            display: flex;
            width: 100%;
            &:before {
                content: counter(my-awesome-counter) ".";
                font-weight: bold;
                font-size: 3.5rem;
                margin-right: 1rem;
                margin-left: -2.7rem;
                @media only screen and (max-width: 767px) {
                    margin-left: -1rem;
                } 
                line-height: 1;
                color: #DDDDDD;
              }
              p {
                  clear: both;
              }
          }
      }
}

.program-structure {
    p {
        border-top: 2px dashed;
        border-color: grey !important;
        margin:0 !important; 
        padding: 30px !important;
        counter-increment: section;
        position: relative !important;
        color: white !important;

      &:nth-child(even) {
        border-left: 2px dashed;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        margin-right: 30px !important; 
        padding-right: 0 !important;
        padding-left: 100 !important;
        .livemarker {
            content: "LIVE";
            font-size: 60%;
            right: 100%; 
            margin-right: -20px;
            position: absolute;
            border-radius: 50%;
            padding: 4px;
            height: 40px;
            width: 40px;
            background-color: #181b24;
            border: 2px dashed grey;
            text-align:center;
            color: white;
            &:nth-child(2) {
                margin-top: 80px;
            }
        }
        // &:before {
        //     content: counter(section);
        //     right: 100%; 
        //     margin-right: -20px;
        //     position: absolute;
        //     border-radius: 50%;
        //     padding: 10px;
        //     height: 50px;
        //     width: 50px;
        //     background-color: blue;
        //     text-align:center;
        //     color: white;
        //     font-size: 110%;
        // }
      }
      
      &:nth-child(odd) {
        border-right: 2px dashed;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        margin-left: 30px !important; 
        padding-left: 0px !important;
        .livemarker {
            font-size: 60%;
            left: 100%; 
            margin-left: -20px;
            position: absolute;
            border-radius: 50%;
            color: white;
            padding: 4px;
            height: 40px;
            width: 40px;
            background-color: #181b24;
            border: 2px dashed grey;
            text-align:center;
            &:nth-child(2) {
                margin-top: 60px;
            }
        }
        // &:before {
        //     content: counter(section);
        //     left: 100%; 
        //     margin-left: -20px;
        //     position: absolute;
        //     border-radius: 50%;
        //     padding: 10px;
        //     height: 50px;
        //     width: 50px;
        //     background-color: blue;
        //     text-align:center;
        //     color: white;
        //     font-size: 110%;
        // }
      }
      
      &:first-child {
        border-top: 0;
        border-top-right-radius:0;
        border-top-left-radius:0;
        padding-top: 0 !important;
        .livemarker {
            &:first-child {
                margin-top: 0px;
            }
            &:nth-child(2) {
                margin-top: 60px;
            }
        }
      }
      
      &:last-child {
        border-bottom-right-radius:0;
        border-bottom-left-radius:0;
        padding-bottom: 0 !important;
        .livemarker {
            bottom: 0;
            &:first-child {
                margin-bottom: 0px;
            }
            &:nth-child(2) {
                margin-bottom: 60px;
            }
        }
      }
    }  
}

.flextry {
    margin: 0px -30px;
    .flex-container {
        display: flex;
        flex-wrap: nowrap;
        width: calc(100% + 60px);

      }
      
      .flex-container > div {
        position: relative;
        background-color: black;
        flex-grow: 1;
        margin: 5px;
        text-align: center;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 4px;
        min-height: 350px;
        display: flex;
        flex-direction: column;        
        .text-over {
            min-height: 325px;
        }
        h5 {
            text-align: left !important;
            color: #FFFFFF;
        }
        p {
            display: none;
        }
        
        &:hover {
            p {
                display: block;
                width: 100%;
                position: absolute;
                text-align: left;
                color: rgba(255,255,255,0.7);
                padding: 20px 10px 5px 10px;
                font-size: 0.8em;
                bottom: 0;
                margin-left: 0%;
                border-radius: 0 0 4px 4px;
                margin-bottom: 0;
                line-height: 1.2;
                background: rgba(0,0,0,0.4);
                background: linear-gradient(0deg, rgba(0,0,0,0.8491771708683473) 0%, rgba(0,0,0,0.7) 75%, rgba(0,212,255,0) 100%);
            }
        }
      }
      .flex-container > div > div {
          position: relative;
        background-color: black;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
        text-align: center;
        border-radius: 4px;
        background-size: cover;
        background-position: center center;
        &:last-child {
            margin-bottom: 0px;
        }
        span {
            display: none;
        }
        
        &:hover {
            span {
                display: block;
                position: absolute;
                text-align: left;
                width: 100%;
                color: rgba(255,255,255,0.7);
                padding: 20px 10px 5px 10px;
                font-size: 0.8em;
                border-radius: 0 0 4px 4px;
                bottom: 0;
                line-height: 1.2;
                background: rgba(0,0,0,0.4);
                background: linear-gradient(0deg, rgba(0,0,0,0.8491771708683473) 0%, rgba(0,0,0,0.7) 75%, rgba(0,212,255,0) 100%);
            }
        }
       
      }
      #MainBanner {
        flex-grow: 1;
        position: relative;
        overflow: hidden;
        background-color: black;
        max-width: 240px;
        border-radius: 4px;
        video {
            top: 0px;
            left: 0px;
            min-width: 100%;
            min-height: 100%;            
        }
    }
}

.vertical-text {
    transform: rotate(-90deg);
    width: 200px;
    position: absolute;
    bottom: 60px;
    right: -80px;
}
.pricing-bar {
    position: fixed;
    bottom: 0;
    background: black;
    color: white;
    z-index: 100;
    padding-top: 20px;
    box-shadow: 2px -8px 16px 4px rgba(0,0,0,0.15);
    line-height: 1;
}

.header {
    .featured-image {
        min-height: 325px;
        background-size: cover;
        border-radius: 4px;
        @media only screen and (max-width: 767px) {
            min-height: 175px;
        }
    }
}

.category-block {

    .featured-image {
        min-height: 250px;
        background-size: cover;
        border-radius: 4px;
        @media only screen and (max-width: 767px) {
            min-height: 175px;
        }
    }

    .border-left {
        @media only screen and (max-width: 767px) {
            border-left: none !important;
        }  
    }
    .author {
        font-size: 0.8rem;
    }
}

.dark-background {
    .border-bottom {
        border-bottom-color: grey !important;
    } 
    .border-left {
        border-left-color: grey !important;     
    }
}

.post-content {
    p {
        margin: 0px !important;
        word-wrap: break-word !important;
        font-size: 18px !important;
        font-weight: 400 !important;
        line-height: 1.6666666666666667em !important;
        margin-bottom: 1.5rem !important;
    }
    .blockquote {
        border-left: 2px solid #484848;
        padding-left: 20px;
        margin-bottom: 1.5rem;
        p {
            font-size: 1.5rem !important;
            margin-bottom: 0px !important;
        }
    }
    .embed-responsive {
        margin-left: -3.5rem;
        width: calc(100% + 4.5rem);
        margin-bottom: 1.5rem;
    }   
    ol {
        list-style: none;
        counter-reset: my-awesome-counter;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        li {
            counter-increment: my-awesome-counter;
            display: flex;
            width: 100%;
            &:before {
                content: counter(my-awesome-counter) ".";
                font-weight: bold;
                font-size: 3.5rem;
                margin-right: 1rem;
                margin-left: -2.7rem;
                @media only screen and (max-width: 767px) {
                    margin-left: -1rem;
                } 
                line-height: 1;
                color: #DDDDDD;
              }
              p {
                  clear: both;
              }
          }
      }
}

.link-card {
    cursor: pointer;
    .meta-image {
        background-size: cover;
    }
    .card-text {
        margin-bottom: 0.1rem !important;
    }
    .url {
        font-size: 0.7rem !important;
        margin-bottom: 0.1rem !important;
        opacity: 0.7;
    }
    .title {
        font-size: 1.1rem !important;
        font-weight: 600 !important;
        line-height: 1.2 !important;
        margin-bottom: 0.5rem !important;
    }
    .meta-description {
        font-size: 0.8rem !important;
        line-height: 1.2 !important;
        margin-bottom: 0.1rem !important;
        opacity: 0.7;
    }
}

.consult-search {
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: red;
        opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: red;
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: red;
      }
}

.academyProgram {
    .form-control {
        border: 0;
        padding: 0;
        background-color: transparent;
        box-sizing:border-box;
        color: inherit;
        font-size: inherit;
    }
}
.fb_dialog_content > iframe {
    bottom: 70px !important;
    //default iframe style
    // margin: 0px 12px; padding: 0px; position: fixed; z-index: 2147483644; bottom: 24px; top: auto; right: 12px; height: 60px; width: 60px; border-radius: 29px; box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px 0px; background: none; display: block;
}

// custom css for dashboard and other admin pages 
// *begins here*

.dashBoard {
    &--card-icon{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 4rem;
        left: 1rem;
        color: rgba(255,255,255,0.2);
    }
}


.btn-cross {
    font-size: 0.8rem !important;
    padding: 0.35rem 0.45rem !important;
    line-height: 1 !important;
}

// faculty

.faculty {
    &-icon{
        font-size: 2rem;
        
    }
}


// modal close white button

.modal-custom{
    button{
        color: #fff !important;
    }
}

// live 

@keyframes livevideo {
    0%  {color: #3d5aff;}
    50%  {color: #0fa0e4;}
    100% {color: rgb(87, 202, 248);}
  }

.live-today {
    margin-right: 0em !important;
    margin-left: 0.5em;
    animation-name: livevideo;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-direction: alternate;
    animation-iteration-count: infinite;
  }

// today dot

.dot{
    display: inline-block;
    border-radius: 50%;
    width: 50%;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    
  &.dot-success{
    background-color: #0fa0e4;
  }

}

.ckeditorNoPadding .ck{
    padding: 0 !important;
}

.ckeditorNoMargin p{
    margin: 0 !important;
    overflow-y: hidden !important;
}